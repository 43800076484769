@import "../globals.scss";

.about-window-background {
  background-image: url("/img/gallery.png"); //Todo : change
  background-size: cover;
}

.about-container {
  background-color: $primary-light-color-transparent;
  border: 1px solid $primary-color;
  width: 60vw;
  height: 50vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-container::before {
  color: $primary-light-color-transparent;
  z-index: -1;
  content: "";
  position: absolute;
  bottom: -6vh;
  left: 0;
  font-size: 20vw;
  font-weight: 900;
}

.what-content::before {
  content: "What";
}

.how-content::before {
  content: "How";
}

.why-content::before {
  content: "Why";
  bottom: 0rem !important ;
}

.who-content::before {
  content: "Who";
}

@media only screen and (max-width: 1439px) {
  .about-container::before {
    bottom: 0;
  }
}
