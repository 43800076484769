@import "../globals.scss";

.button-outer-container {
  position: relative;
  margin: 0.8rem;

  .button-container {
    transform: translate(4px, 4px);
    border: 1px solid $primary-color;
    padding: 1rem -1rem 1rem 1rem;
    display: inline-block;
    button {
      transform: translate(-4px, -4px);
      transition: all 0.1s linear 0s;
      font-size: 24px;
      font-style: italic;
      font-weight: 300;
      font-family: Helvetica, sans-serif;
      padding: 1rem 2rem;
      border: 1px solid $primary-color;
      color: $primary-color;
      background-color: $white;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      margin: 0;
      text-decoration: none;
      cursor: pointer;
    }
    button:hover {
      transform: translate(-2px, -2px);
    }
    button:hover,
    button:focus {
      outline: none;
      border: 1px solid $primary-color;
      color: $primary-color;
      background-color: $white;
    }
    button:active {
      transform: translate(0px, 0px);
      outline: none;
      color: $white;
      background-color: $primary-color;
    }

    // https://stackoverflow.com/a/199319/3103033
    button::-moz-focus-inner {
      border: 0;
    }
  }
}
