@import "../globals.scss";

footer {
  height: 8vh;
  width: 100vw;
  background-color: $primary-light-color;
  border-top: 1px solid $primary-color;
  display: flex;
  align-items: center;
  .copyright {
    font-style: italic;
    text-align: center;
    font-size: 14px;
    color: $light-grey;
    margin: auto;
  }
}
