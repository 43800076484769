@import "../globals.scss";

.main-application-container {
  height: 82vh;
  .connection-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .about-container {
    background-color: $primary-light-color-transparent;
    border: 1px solid $primary-color;
    width: 60vw;
    height: 50vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .about-nav {
      text-decoration: none;
    }
    .about-nav li {
      display: inline-block;
    }
  }
}
