#carousel {
  position: absolute;
  transform-style: preserve-3d;
  // transition: transform 1s;
  backface-visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  // height: 60vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;

  figure {
    // font-size: 3vw;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-sizing: border-box;
    // border: 2px solid black;
    width: 100%;
    // height: inherit;
    margin: 0;
    top: 0;
    left: 0;
    user-select: none;
  }
}
